.search {
    background: #f2f2f2;
    padding-top: 30px;
    padding-bottom: 30px;
}

.search .searchTitle {
    min-width: max-content;
    display: flex;
    align-items: center;
}

.search .searchTitle h3 {
    text-transform: uppercase;
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin-bottom: 0px;
}

.search .searchTitle h6 {
    font-size: 18px;
    color: #444;
    font-weight: 300;
    margin-left: 20px;
    margin-bottom: 0px;
}

.search .searchResults .productCardGrid {
    /* margin-right: 5px; */
}

.search .searchLt .customSelect {
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
}

.search .searchLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.search .searchLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.search .searchLt .gridListToggle i {
    padding-right: 10px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 35vmax;
    max-width: 800px;
    padding: 20px;
}

.search .filterCnt {
    justify-content: flex-end;
}

.search .filterCnt .showFilter {
    display: none;
    font-weight: 600;
    text-transform: initial;
    border-color: var(--primColor);
}

.search .filterCnt .showFilter .material-icons {
    padding-right: 5px;
}

.search .filterCnt .showFilter {
    color: var(--primColor);
}

.search .filterClear {
    padding-top: 0;
}

.search .filterClear .MuiButton-label {
    color: var(--primColor);
    font-size: 14px;
    text-transform: initial;
}

.search .filterClear .MuiButton-label .material-icons {
    font-size: 18px;
    padding-right: 5px;
}

.responsiveFilterDrawer .filterPanel {
    max-width: 300px;
    width: 100%;
    padding: 20px;
}

.responsiveFilterDrawer .filterPanel .fpTitle {
    justify-content: flex-start;
}

.responsiveFilterDrawer .filterPanel .MuiAccordionSummary-content {
    justify-content: flex-start;
    margin-bottom: 0;
    margin-top: 0;
}

.responsiveFilterDrawer .filterPanel .filterCheck .MuiListItem-button {
    justify-content: flex-start;
}

.responsiveFilterDrawer
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    height: 35px;
}

.responsiveFilterDrawer .dashboardFilter {
    width: 300px;
}

.responsiveFilterDrawer .filterPanel .filterCheck .filterCB {
    float: left;
}

.responsiveFilterDrawer .filterPanel .fpTitle h4 {
    font-size: 16px;
    margin-bottom: 15px;
}

.filterPanel .MuiAccordionDetails-root {
    justify-content: flex-start;
}

.filterTagsCnt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.filterTagsCnt .filterTags {
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 10px;
    font-weight: 500;
    color: white;
    background-color: black;
    text-transform: capitalize;
    width: max-content;
    display: flex;
    align-items: center;
}

.filterTagsCnt .filterTags .material-icons {
    font-size: 18px;
    color: var(--primColor);
    margin-left: 5px;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .home .hmSearch .searchInput,
    .search .searchInput {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .filterTagsCnt {
        margin-bottom: 15px;
    }

    .search .searchLt,
    .dashboard .dashboardLt {
        display: none;
    }

    .search .filterCnt {
        justify-content: space-between;
    }

    .search .filterCnt .showFilter {
        display: inline-flex;
    }

    .sbTitle {
        font-size: 14px;
        color: var(--secColor);
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .searchAuctions {
        margin-bottom: 20px;
    }

    .searchAuctions label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        color: var(--primColor);
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid #d8d8d8;
        border-radius: 25px;
        margin-right: 10px;
        text-decoration: none;
        transition: 200ms all ease-in-out;
        font-size: 16px;
        font-weight: 600;
    }

    .searchAuctions input[type='radio']:checked + label {
        background: rgb(249 238 236);
        color: var(--primColor);
        border: 1px solid rgb(174 34 7 / 51%);
    }
}

@media (max-width: 991px) {
    .searchTitleCnt {
        flex-direction: column-reverse;
    }
    .search .searchTitle {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
}

@media (max-width: 600px) {
    .searchAuctions label {
        height: 40px;
        font-size: 14px;
    }

    .search .searchCnt {
        margin-top: 0;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }
    .productView .pvBidBox .customInput,
    .productView .pvBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }
    .productView .pvBidBox .customInput input {
        height: 100%;
    }
    .productView .pvActBtn {
        flex-wrap: wrap;
    }
    .productView .pvActBtn .pabChild:first-child {
        width: 100%;
    }
}

@media (max-width: 445px) {
    .search .searchTitle h3 {
        font-size: 16px;
    }
    .search .searchTitle h6 {
        font-size: 14px;
        margin-left: 15px;
    }
    .search {
        padding-top: 15px;
    }
}
