.support .customTextArea .MuiFormControl-root {
    width: 100%;
}

.support {
    margin: 60px auto;
    width: 100%;
    max-width: 940px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 5px;
}

.support .primButton {
    margin: 30px auto 0;
    width: 150px;
}

.support .supportFaq {
    text-align: left;
}

.support .supportFaq h3 {
    font-size: 18px;
    font-weight: 600;
    color: #666;
    margin: 10px 0;
}

.support .supportFaq p {
    font-size: 14px;
    color: #666;
    margin: 0 0 20px;
}
