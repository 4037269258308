footer {
    /* padding-top: 30px;
  padding-bottom: 30px; */
    margin-top: 15px;
    text-align: left;
}

footer .footCnt {
    background: #fff;
}

footer .footLogo img {
    margin-right: 30px;
    width: 220px;
    object-fit: contain;
}

footer .footCnt .footLt,
footer .footCnt .footRt {
    padding: 15px 0px;
    align-self: stretch;
}

footer .footCnt .footLt {
    background: #ececec;
    width: 50%;
    padding-right: 20px;
    padding-left: 9%;
}

footer .footCnt .footRt {
    width: 50%;
    background: #fff;
    padding-left: 20px;
    padding-right: 9%;
}

footer .footCnt .footRt h3 {
    font-size: 20px;
    color: #444;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}

footer ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

footer ul li a {
    color: #525252 !important;
    position: relative;
    font-size: 15px;
    text-decoration: none;
}

footer ul li a:hover {
    color: #000;
}

footer .footLinks ul {
    display: flex;
}

footer .footLinks ul li:not(:last-child) {
    margin-right: 40px;
}

footer .footRt .footSubscribe {
    width: max-content;
    border: 1px solid #ccc;
    background: #fff;
    padding: 5px;
}

footer .footRt .footSubscribe form {
    border-radius: 0px;
    height: auto;
    margin-bottom: 0px;
    flex-wrap: nowrap;
}

footer .footRt .footSubscribe form .customInput {
    margin-bottom: 0;
    background: #fff;
    border: none;
    color: #8a8a8a;
    height: inherit;
}

.footSubscribe .MuiFormLabel-root {
    color: #8a8a8a;
}

footer .footRt .footSubscribe form .subsInp {
    align-self: stretch;
}

footer .footRt .footSubscribe form .subsInp input {
    background: #fff;
}

footer .footRt .footSubscribe form button {
    height: auto;
    align-self: stretch;
    color: #fff;
    display: flex;
    width: 150px;
    border-radius: 0px;
    background: var(--primColor);
}

footer .footRt .footSubscribe form .MuiFilledInput-underline:before {
    border-bottom: transparent;
}

footer .footRt .footSubscribe form .MuiFilledInput-underline:after {
    border-bottom: 1px solid var(--primColor);
}

footer .footRt .footSubscribe form input:focus {
    box-shadow: none;
    border: none;
}

footer .footRt .footSubscribe form input::placeholder {
    color: #fff;
}

footer .footRt .footSubscribe small {
    color: #dfdfdf;
}

footer .footCopyright {
    margin: 0;
    color: #dfdfdf;
    background: var(--secColor);
    text-align: center;
    font-size: 12px;
    margin-top: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
}

footer .footCopyright p {
    margin-bottom: 0;
}

footer .footCopyright ul {
    display: flex;
    align-items: center;
    margin: auto;
}

footer .footCopyright ul li:not(:last-child) {
    margin-right: 25px;
}

footer .footCopyright ul li a {
    font-size: 15px;
    color: var(--accentColor) !important;
    text-decoration: none;
}

footer .footCopyright ul li a:hover {
    color: #fff !important;
}

@media (max-width: 1366px) {
    footer .footCnt .footLt {
        padding-left: 20px;
    }
    footer .footCnt .footRt {
        padding-right: 20px;
    }
}

@media (max-width: 1024px) {
    footer .footCnt .footRt {
        flex-wrap: wrap;
        width: 40%;
    }
    footer .footCnt .footRt h3 {
        width: 100%;
        margin-bottom: 10px;
        font-size: 15px;
    }
    footer .footCnt .footLt {
        width: 60%;
    }
}

@media (max-width: 991px) {
    footer .footCnt .footLt {
        width: 100%;
    }
    footer .footCnt .footRt {
        width: 100%;
        text-align: center;
    }
    footer .footRt .footSubscribe {
        margin: 0 auto;
    }
}
