.filterPanel .fpTitle {
    justify-content: flex-end;
}

.filterPanel .fpTitle h4 {
    font-size: 13px;
    color: #b2b2b2;
    font-weight: 400;
    padding-right: 20px;
    text-align: left;
    margin-bottom: 5px;
}

.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    /* border: 1px solid #e4e4e4; */
    min-height: 40px;
    height: 45px;
    border-radius: 5px;
    text-align: right;
}
.filterPanel .MuiCollapse-wrapperInner small {
    width: 100%;
    text-align: right;
}

.filterPanel .MuiPaper-root {
    background-color: transparent;
}

.filterPanel .MuiPaper-root.MuiAccordion-root {
    border: none;
    margin-bottom: 10px;
}

.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .accTitle {
    color: #333031;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .accTitle {
    color: var(--primColor);
}
.filterPanel
    .filterAcc
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon {
    color: var(--primColor);
}

.filterPanel .MuiAccordionSummary-content {
    justify-content: flex-end;
}

.filterTitleCnt {
    margin-bottom: 15px;
}

.filterPanel h4 {
    margin-bottom: 0;
}

.filterPanel .filterClear {
    text-transform: none;
    color: #8e8e8e;
    font-size: 13px;
}

.filterPanel .filterClear .material-icons {
    padding-right: 5px;
    font-size: 20px;
}

.filterPanel .MuiAccordionDetails-root {
    padding: 10px 16px;
    justify-content: flex-end !important;
}

.filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row {
    width: 100%;
}

.filterPanel .filterCheck .MuiFormControlLabel-root,
.filterPanel .filterCheck .MuiTypography-root.MuiFormControlLabel-label,
.filterPanel .filterCheck .MuiListItem-button {
    width: 100%;
    margin: 0;
}

.filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row .MuiCheckbox-root {
    display: none;
}

.filterPanel
    .filterCheck
    .MuiFormGroup-root.MuiFormGroup-row
    .MuiCheckbox-root.Mui-checked
    ~ span
    .MuiListItem-root {
    color: var(--primColor);
}

.filterCheck .filterCB {
    float: right;
    width: 100%;
}

.filterPanel .filterCheck .MuiListItem-button {
    padding: 5px;
    font-size: 14px;
    color: #666;
    justify-content: flex-end;
}

.RadioBox .MuiListItem-button {
    padding: 0;
}

.RadioBox .MuiAccordionDetails-root {
    padding-top: 0;
}

.filterPanelSkeleton {
    /* height: 60vh; */
    margin-bottom: 30px;
}

@media (max-width: 1024px) {
    .filterPanel .MuiCollapse-wrapperInner small {
        width: 100%;
        text-align: left;
    }
}
