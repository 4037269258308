.registration {
    /* max-width: 800px; */
    margin: 60px auto;
    background: #fff;
    border-radius: 5px;
    padding: 45px;
}

.registration .regBox {
    width: 100%;
}

.registration .regTitle {
    font-size: 22px;
    position: relative;
    margin-bottom: 25px;
    color: #666;
    font-weight: 400;
    text-transform: uppercase;
}

.registration .regTitle::before {
    position: absolute;
    content: '';
    border-bottom: 1px solid #bdbdbd;
    height: 1px;
    width: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
}

.registration .regTitle span {
    display: inline-block;
    padding: 0 30px;
    position: relative;
    z-index: 1;
    background: #fff;
}

.registration .brandLogo {
    margin-bottom: 25px;
    max-width: 250px;
}

.registration .stepLabel {
    color: #666;
    font-size: 24px;
    text-align: center;
    text-transform: capitalize;
}

.registration .crActBtn {
    max-width: max-content;
    margin: 0 auto;
    margin-bottom: 20px;
}

.registration .regMiscAction p {
    color: var(--accentColor);
    font-size: 16px;
    margin: 0;
    text-align: center;
    width: 100%;
}

.registration .regMiscAction p {
    color: var(--primColor);
    font-size: 16px;
    margin: 0;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.registration .regMiscAction .material-icons {
    padding-right: 5px;
}

.registration .crActBtn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.registration .crActBtn .primButton,
.registration .crActBtn .secButton,
.registration .regSuccess .primButton {
    min-width: 240px;
    margin: 20px auto;
}

.registration .crActBtn .secButton {
    margin-right: 15px;
}

.regCategories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    gap: 15px;
}

.regCategories .categorySelect .MuiButtonBase-root.MuiListItem-root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.regCategories .categorySelect .MuiFormGroup-root label {
    margin: 0;
    border-radius: 6px;
    border: 0;
    padding: 10px;
    text-align: center;
    color: #666;
    font-weight: 400;
    background: #fff;
    box-shadow: 0 0 10px 0 #dadada;
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;
}

.regCategories .categorySelect .MuiFormGroup-root label .MuiIconButton-label {
    display: none;
}

.regCategories .categorySelect .MuiTypography-root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100px;
    padding: 10px;
    padding: 0;
}

.regCategories .categorySelect .MuiTypography-root img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.regCategories .categorySelect .MuiTypography-root p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.regCategories .categorySelect .MuiCheckbox-root {
    display: none;
}

.regCategories .categorySelect .MuiIconButton-root.Mui-checked ~ .MuiTypography-root {
    border: none;
    background: #0e7340;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    padding: 0;
    /* background-image: linear-gradient(to bottom, #7fec93 15%, #20bc4c 60%, #0e7340 90%); */
    background: var(--primColor);
}

.regCategories .categorySelect .MuiIconButton-root.Mui-checked ~ .MuiTypography-root img {
    filter: brightness(0) invert(1);
}

.registration .regTerms {
    width: 100%;
    max-width: 500px;
    padding: 15px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 30px auto;
    border: 1px solid #bdbdbd;
    text-align: left;
}

.registration .regTerms h4 {
    color: #666;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
}

.registration .regTerms p {
    font-size: 14px;
    color: #666;
    line-height: 22px;
}

.registration .regTerms p:before {
    content: '';
    padding: 0 18px;
}

.registration .regTnc .MuiFormControlLabel-root {
    margin: 0 auto 15px;
}

.registration .regSuccess {
    max-width: 800px;
    margin: 0 auto;
}

.registration .regSuccess h3 {
    font-weight: 400;
    color: #666;
    margin: 20px auto 25px;
    font-size: 24px;
}

.registration .regSuccess h5 {
    font-size: 16px;
    margin: 0 auto 45px;
    max-width: 580px;
    color: #666;
}

.registration .MuiStepper-root {
    padding: 25px 25px 50px;
}

.registration .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    z-index: 1;
}

@media (max-width: 800px) {
    .regCategories {
        grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
        width: 100%;
        padding: 15px;
    }
}

@media (max-width: 600px) {
    .registration {
        max-width: initial;
        margin: 0 auto;
        height: 100%;
        padding: 45px 15px;
    }
    .registration .brandLogo {
        max-width: 200px;
    }

    .registration .primButton {
        width: 100%;
        margin: 15px auto 20px;
    }
    .registration .MuiStepper-root {
        padding: 25px;
    }

    .registration .stepLabel {
        font-size: 18px;
    }
    .registration .crActBtn {
        flex-wrap: wrap;
        max-width: initial;
        flex-direction: column-reverse;
    }
    .registration .crActBtn .secButton {
        margin: 0;
        width: 100%;
    }
    .regCategories .categorySelect .MuiTypography-root p {
        font-size: 15px;
    }
    .regCategories .categorySelect .MuiTypography-root {
        height: 70px;
    }
}

@media (max-width: 450px) {
    .registration .MuiStepper-root {
        padding: 25px 0;
    }
    .registration .regTerms {
        margin-bottom: 10px;
    }
    .registration .regSuccess h5 {
        font-size: 15px;
        margin: 0 auto 25px;
        max-width: initial;
        line-height: 20px;
        padding: 0 15px;
    }
    .registration .regTnc .MuiFormControlLabel-root {
        margin: 0;
    }

    .registration .regTnc .MuiFormControlLabel-root .MuiListItem-button {
        font-size: 14px;
    }

    .registration .regTnc p {
        margin: 0;
    }
}

@media (max-width: 400px) {
    .regCategories {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
}
