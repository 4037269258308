.loginContainer {
    /* height: calc(100vh - 145px); */
    height: 100%;
    padding-bottom: 30px;
}

.login {
    max-width: 500px;
    margin: 60px auto;
    background: #fff;
    border-radius: 5px;
    padding: 45px;
}

.login .brandLogo {
    margin-bottom: 25px;
    max-width: 250px;
}

.login .loginTitle {
    font-size: 22px;
    position: relative;
    margin-bottom: 45px;
    color: #666;
    font-weight: 400;
    text-transform: uppercase;
}

.login .loginTitle::before {
    position: absolute;
    content: '';
    border-bottom: 1px solid #bdbdbd;
    height: 1px;
    width: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
}

.login .loginTitle span {
    display: inline-block;
    padding: 0 30px;
    position: relative;
    z-index: 1;
    background: #fff;
}

.login .loginActBox .MuiButtonBase-root.MuiListItem-root {
    padding-left: 0;
}

.login .primButton {
    margin: 15px auto 30px;
    width: 240px;
}

.login .MuiDivider-root {
    margin: 25px 0;
}

.login .loginMiscAction p {
    color: var(--accentColor);
    font-size: 16px;
    margin: 0;
    text-align: center;
    width: 100%;
}

.login.forgotPassword .loginMiscAction p {
    color: var(--primColor);
    font-size: 16px;
    margin: 0;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .loginMiscAction .material-icons {
    padding-right: 5px;
}

.login.forgotPassword h4 {
    font-size: 18px;
    color: var(--accentColor);
    margin-bottom: 25px;
    line-height: 22px;
}

.login.forgotPassword .primButton {
    margin-top: 0;
}

/* .login.resetPassword h2{
  margin-bottom: 35px;
} */

.loginActBox .MuiFormControlLabel-root {
    margin: 0;
}

@media (max-width: 600px) {
    .login {
        max-width: initial;
        margin: 0 auto;
        height: 100%;
        padding: 45px 15px;
    }
    .login .brandLogo {
        max-width: 200px;
    }

    .loginContainer {
        height: calc(100vh - 72px);
        padding-bottom: 0;
    }
    .login .primButton {
        width: 100%;
        margin: 15px auto 20px;
    }
}

@media (max-width: 500px) {
    .login .loginMiscAction p,
    .login.forgotPassword h4 {
        font-size: 14px;
    }
    .login .loginTitle {
        margin-bottom: 25px;
    }
    .login a {
        font-size: 15px;
    }
}

@media (max-width: 340px) {
    .login .loginActBox .MuiButtonBase-root.MuiListItem-root,
    .login .loginActBox a {
        font-size: 14px;
    }
}
