.auctionView {
    text-align: left;
}

.auctionView .favoriteCheck {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.auctionView .pvTitle {
    font-size: 20px;
    margin: 0 0 15px;
    color: var(--primColor);
    line-height: 1.35;
    letter-spacing: 0.6px;
    font-weight: 500;
}

.auctionView .pvInfo > .pvGrid > .pvGrid {
    min-width: 50%;
}

.auctionView .pvGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    text-align: left;
}

.auctionView .pvGrid label {
    font-size: 13px;
    color: #b2b2b2;
    min-width: 90px;
    padding: 5px 0;
    margin: 0;
}

.auctionView .pvGrid span {
    color: #666;
    padding: 5px 0;
    font-size: 14px;
    text-transform: capitalize;
    word-break: break-word;
}

.auctionView .catgGrid .pvGrid {
    grid-template-columns: 1fr 3.2fr;
}

.auctionView .pvDesc label {
    padding-bottom: 8px;
    font-size: 16px;
    color: #b2b2b2;
    margin: 0;
}

.auctionView .pvDesc p {
    font-size: 14px;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.auctionView .image-gallery-thumbnails-container {
    text-align: left;
}

.auctionView .image-gallery-thumbnail {
    width: 70px;
    height: 70px;
    overflow: hidden;
}

.auctionView .image-gallery-thumbnail > div {
    width: inherit;
    height: inherit;
}

.auctionView .image-gallery-thumbnail img {
    height: 100%;
    object-fit: cover;
}

.auctionView .image-gallery-thumbnail,
.auctionView .image-gallery-thumbnail,
.auctionView .image-gallery-thumbnail {
    border: 2px solid transparent;
}

.auctionView .image-gallery-thumbnail.active,
.auctionView .image-gallery-thumbnail:hover,
.auctionView .image-gallery-thumbnail:focus {
    border: 2px solid var(--primColor);
}

.image-gallery-content .image-gallery-fullscreen-button {
    width: 100%;
    top: 0;
    height: 100%;
    opacity: 0;
    z-index: 0;
}

.lotDetails .table .thead-dark th {
    border-bottom: 2px solid #444;
    background: #444;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: none;
    font-size: 14px;
}

.font-25 {
    font-size: 25px;
}
.lotDetails .table .thead-dark th:first-child {
    border-top-left-radius: 5px;
}

.lotDetails .table .thead-dark th:last-child {
    border-top-right-radius: 5px;
}

.lotDetails h2 {
    font-size: 24px;
    color: #666;
    font-weight: 700;
    margin: 30px 0 20px;
}

.auctionView {
    background: #fff;
}

.auctionView .customTabs {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 40px;
}

.auctionView .customTabs .primButton button {
    width: max-content;
}

.auctionView .MuiAppBar-root.MuiAppBar-colorPrimary {
    padding: 0;
    background-color: #e5e5e5;
    box-shadow: none;
}

.auctionView .MuiAppBar-root .MuiButtonBase-root.MuiTab-root {
    color: #333;
    border-radius: 5px;
    text-transform: initial;
}

.auctionView .MuiAppBar-root .MuiButtonBase-root.MuiTab-root.Mui-selected {
    background: #fff;
    color: #333;
}

.auctionView .MuiAppBar-root .MuiTabs-indicator {
    background-color: transparent;
}

.auctionView .MuiBox-root {
    background: #fff;
    padding: 20px;
}

.auctionView .lotCount {
    margin: 0;
    font-size: 16px;
    padding-bottom: 20px;
    color: #666;
}

.assuranceCnt {
    background: #f2f2f2;
}

.assuranceCnt .assuranceCard {
    box-shadow: 0 0px 10px 0 rgb(0 0 0 / 8%);
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    margin-top: 24px;
    background: #f2f2f2;
    border: 2px solid #e5e5e5;
    position: relative;
}

.assuranceCnt .assuranceCard > .material-icons {
    font-size: 48px;
    color: var(--primColor);
    margin-right: 30px;
}

.assuranceCnt .assuranceCard h2 {
    font-size: 20px;
    margin: 0 0 15px;
    color: var(--primColor);
    line-height: 1.35;
    letter-spacing: 0.6px;
    font-weight: 600;
}

.assuranceCnt .assuranceCard p {
    font-size: 14px;
    margin: 0 0 15px;
    color: #666;
}

.assuranceCnt .assuranceCard .leaf {
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.assuranceCnt .assuranceCard .leaf .material-icons {
    font-size: 32px;
    color: var(--primColor);
}

/* LINE ITEM AUCTION */

.lineItemTimerCnt {
    padding: 10px 15px;
    text-align: center;
    background: #f2f2f2;
    margin-bottom: 15px;
    position: relative;
    height: 65px;
}

.lineItemTimerCnt h2 {
    font-size: 18px;
    font-weight: 700;
    color: var(--primColor);
}

.lineItemTimerCnt h6 {
    font-size: 14px;
    color: #666;
}

.lineItemInfo {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
}

.ndCTA {
    display: inline-block;
    position: relative;
    text-align: center;
}

.ndCTA button {
    background: var(--primColor);
    color: #fff;
    width: 150px;
}

.ndCTA button:hover {
    background: var(--primColor);
}

.ndCTA .arrow {
    position: absolute;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
}
.ndCTA .arrow span {
    display: block;
    width: 25px;
    height: 25px;
    border-bottom: 5px solid var(--primColor);
    border-right: 5px solid var(--primColor);
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

.ndCTA .arrow span:nth-child(2) {
    animation-delay: -0.2s;
}
.ndCTA .arrow span:nth-child(3) {
    animation-delay: -0.4s;
}
.auctionView .pswp__button--share {
    display: none !important;
}

.auctionView .pswp-gallery .pswp-thumbnails {
    display: none !important;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.lineItemView .currDetails {
    margin: 25px 0;
}

.lineItemView .currDetails .customSelect {
    margin: 0;
    width: 200px;
}

.lineItemMiscActions .secButton {
    margin-left: 15px;
}

.lineItemMiscActions .secButton .MuiButton-label {
    text-transform: initial;
}

.lineItemMiscActions .secButton .material-icons {
    padding-right: 5px;
}

.lineItemView .lotDataSort .customSelect {
    width: 150px;
}

.lineItemView .lineItemAction {
    max-width: 300px;
}

.lineItemView .lineItemAction .customInput {
    margin-bottom: 0;
    margin-right: 10px;
}

.lineItemView .lineItemAction .primButton .MuiButton-label {
    text-transform: initial;
}

.lineItemView .lineItemAction p {
    margin-bottom: 5px;
    color: var(--primColor);
    font-size: 12px;
    font-weight: 600;
}
.lineItemView .lineItemAction p.red {
    margin-bottom: 5px;
    color: red;
    font-size: 12px;
    font-weight: 600;
}
.dwBidStatus {
    position: fixed;
    z-index: 100;
    top: 500px;
    right: 10px;
    opacity: 1;
    cursor: pointer;
}
.dwBidStatus a {
    font-weight: 500;
    text-transform: initial;
    font-size: 16px;
    color: var(--primColor);
    background: #fff;
    padding: 10px;
}
.lineItemView table td.lotWinning {
    color: var(--primColor);
}

.lineItemView .table td,
.lineItemView .table th {
    vertical-align: middle;
}

.pvSlider h2 {
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: 700;
    color: #333;
}

.pvSlider ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.pvSlider ul li {
    display: flex;
}

.pvSlider ul li label {
    margin-bottom: 0;
    margin-right: 15px;
}

.pull-right {
    float: right;
}

.image-gallery-slides {
    height: 325px !important;
}

.image-gallery-slides .image-gallery-slide,
.image-gallery-slides .image-gallery-slide > div,
.image-gallery-slides .image-gallery-slide .image-gallery-image {
    height: inherit;
}

.image-gallery-slide .image-gallery-image {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
    cursor: zoom-in;
}

.auctionView .pswp__img {
    object-fit: contain;
}

.image-gallery-fullscreen-button {
    z-index: 0 !important;
}

.customTabs .MuiTabs-root .MuiSvgIcon-root {
    color: #333;
}

.image-gallery-content.fullscreen .image-gallery-slides {
    height: 80vh !important;
}

@media (max-width: 1024px) {
    .auctionView .lineItemInfo .pvGrid > .pvGrid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 991px) {
    .auctionView .lineItemInfo {
        grid-template-columns: 1fr;
    }

    .auctionView .scrollDown {
        display: none;
    }
    .customTabs .MuiTab-root {
        min-width: max-content;
    }
    .auctionView .pvInfo > .pvGrid {
        grid-template-columns: 1fr;
    }
    .auctionView .customContainer {
        padding-left: 15px;
        padding-right: 15px;
    }

    .auctionView.lineItemView .customTabs .primButton button {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .auctionView .catgGrid .pvGrid {
        grid-template-columns: 1fr 1fr;
    }
    .mobileTable.table,
    .mobileTable.table thead,
    .mobileTable.table tbody.mtInner,
    .mobileTable.table th,
    .mobileTable.table td,
    .mobileTable.table tr {
        display: block;
    }
    .mobileTable.table thead {
        display: none;
    }

    .mobileTable.table tbody.mtInner td {
        position: relative;
        padding-left: 50%;
    }

    .mobileTable.table tbody.mtInner td:before {
        position: absolute;
        content: attr(data-title);
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 40%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-size: 13px;
        text-transform: uppercase;
        color: #212121;
        font-weight: 700;
        word-break: break-word;
        white-space: pre-wrap;
    }

    .mobileTable.saTable.table tbody.mtInner td:nth-child(1):before {
        content: 'Brand';
    }
    .mobileTable.saTable.table tbody.mtInner td:nth-child(2):before {
        content: 'Product Name';
    }
    .mobileTable.saTable.table tbody.mtInner td:nth-child(3):before {
        content: 'Category';
    }
    .mobileTable.saTable.table tbody.mtInner td:nth-child(4):before {
        content: 'Part Number';
    }
    .mobileTable.saTable.table tbody.mtInner td:nth-child(5):before {
        content: 'Quantity';
    }

    .mobileTable.laTable.table tbody.mtInner td:nth-child(1):before {
        content: 'Sublot Id';
    }

    .mobileTable.laTable.table tbody.mtInner td:nth-child(2):before {
        content: 'Brand';
    }

    .mobileTable.laTable.table tbody.mtInner td:nth-child(3):before {
        content: 'Product Name';
    }

    .mobileTable.laTable.table tbody.mtInner td:nth-child(4):before {
        content: 'Category';
    }

    .mobileTable.laTable.table tbody.mtInner td:nth-child(5):before {
        content: 'Qty';
    }

    .mobileTable.laTable.table tbody.mtInner td:nth-child(6):before {
        content: 'Current Bid';
    }

    .mobileTable.laTable.table tbody.mtInner td:nth-child(7):before {
        content: 'Status';
    }

    .mobileTable.laTable.table tbody.mtInner td:nth-child(8):before {
        content: 'Action';
    }

    .auctionView.lineItemView .lineItemMiscActions {
        flex-wrap: wrap;
    }

    .auctionView.lineItemView .lineItemMiscActions .secButton {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
    }
    .auctionView.lineItemView .lineItemMiscActions .secButton .MuiButton-label {
        justify-content: space-between;
        flex-direction: row-reverse;
    }
    .lineItemTimerCnt {
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .auctionView .MuiBox-root {
        font-size: 14px;
    }

    .auctionView .MuiBox-root h4 {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .lotDetails h2 {
        font-size: 18px;
    }
    .lineItemView .lotDataSort {
        margin-top: 0 !important;
    }
    .lineItemView .lotDataSort .customSelect {
        width: 100%;
    }
    .auctionView .customContainer {
        padding: 0;
    }

    .dwBidStatus {
        bottom: 20px;
        top: initial;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
    }
    .dwBidStatus a {
        box-shadow: 0px 3px 10px #5a5a5a36;
        border-radius: 5px;
        font-size: 13px;
    }
}

@media (max-width: 480px) {
    .assuranceCnt .assuranceCard {
        flex-wrap: wrap;
    }
    .assuranceCnt .assuranceCard > .material-icons {
        margin: 10px auto;
    }
    .assuranceCnt .assuranceCard p {
        font-size: 12px;
    }
    .assuranceCnt .assuranceCard .leaf {
        right: 10px;
        bottom: 10px;
    }
}

@media (max-width: 445px) {
    .mobileTable.table tbody td:before {
        font-size: 12px;
    }

    .assuranceCnt .assuranceCard {
        padding-bottom: 0;
    }
    .assuranceCnt .assuranceCard .leaf {
        position: relative;
        float: right;
    }
}
